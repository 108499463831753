<template>
  <div class="content-wrap">
    <div class="con_box">
      <div class="content-header">
        <el-row>
          <el-col :span="6">
            <label>结算单号：</label>
            <span>-</span>
          </el-col>
          <el-col :span="6">
            <label>制单日期：</label>
            <span>-</span>
          </el-col>
          <el-col :span="4">
            <label>结算单阶段：</label>
            <span>-</span>
          </el-col>
          <el-col :span="4">
            <label>开票状态：</label>
            <span>-</span>
          </el-col>
          <el-col :span="4">
            <label>收票状态：</label>
            <span>-</span>
          </el-col>
        </el-row>
      </div>
      <div class="preview-layout">
        <div class="content-main">
          <div class="content-table">
            <div class="table-select">
              <div class="select-title">
                <span>明细比对</span>
              </div>
              <div>
                <el-button type="primary">导出</el-button>
                <el-button type="primary">开具</el-button>
              </div>
            </div>
            <div class="table-main">
              <el-table :data="tableData" stripe border :header-cell-style="handleHeaderCellStyle" style="width: 100%">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="merchandiseUniqueCode" label="商品编码" width="150"></el-table-column>
                <el-table-column prop="merchandiseName" label="商品名称"></el-table-column>
                <el-table-column prop="specification" label="规格型号" width="150"></el-table-column>
                <el-table-column prop="unit" label="商品单位" width="100"></el-table-column>
                <el-table-column prop="quantity" label="数量" width="100" align="right" header-align="center"></el-table-column>
                <el-table-column prop="price" label="单价" width="120" align="right" header-align="center" />
                <el-table-column prop="amount" label="金额" width="120" align="right" header-align="center" />
                <el-table-column prop="taxRate" label="税率" width="100" align="right" header-align="center" />
                <el-table-column prop="taxAmount" label="税额" width="120" align="right" header-align="center" />
                <el-table-column prop="sumAmount" label="价税合计" width="120" align="right" header-align="center" />
              </el-table>
            </div>
          </div>
          <div class="content-page">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next" :current-page.sync="page.current" :page-sizes="pageSizes" :page-size="page.size" :total="page.total"> </el-pagination>
          </div>
        </div>

        <!-- <el-dialog append-to-body title="提示" :visible.sync="moreVisible" width="472px" :show-close="false">
        <div class="dialog-wrap">
          <span>是否跳转至<span class="error">开票管理>>发票开具>>开票记录</span>，可执行"发票作废"，"冲红"，"打印"等操作</span>
        </div>
        <div class="dialog-footer" slot="footer">
          <el-button @click="moreVisible = false">取消</el-button>
          <el-button type="primary" @click="handleLinkToBillingInvoice"> 确定 </el-button>
        </div>
      </el-dialog> -->
      </div>
    </div>
  </div>
</template>

<script>
import { tableStyle, pagingMethods, changeMethods } from '@/util/mixins';

export default {
  name: 'PreviewInvoice',
  mixins: [tableStyle, pagingMethods, changeMethods],
  props: ['settlement', 'type'],
  computed: {
    selectItems() {
      return this.invoiceDetailVisible ? 'item-absolute' : 'select-items';
    },
    tableHeight() {
      return document.documentElement.clientHeight - 400;
    },
    billingStatusClass() {
      return this.settlement.billingStatus ? 'success' : 'error';
    }
  },
  data() {
    return {
      tableLoading: false,
      tableData: []
    };
  },

  mounted() {},
  methods: {
    // 导出发票`
    async invoicesExport() {
      // let selection = this.selections;
      // let ids = selection.map((item) => item.id);
      // const excel = await invoicesExport(ids);
      // if (!excel) {
      //   this.toast('导出excel失败', 'error');
      //   return;
      // }
      // let url = window.URL.createObjectURL(
      //   new Blob([excel.data], {
      //     type: 'application/vnd.ms-excel'
      //   })
      // );
      // let link = document.createElement('a');
      // link.style.display = 'none';
      // link.href = url;
      // link.setAttribute('download', 'Excel发票信息.xlsx');
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // this.exportDisabled = false;
    }
  }
};
</script>

<style scoped lang="scss">
.content-wrap {
  background-color: #eaeff8;
  // box-sizing: border-box;
  .con_box {
    padding: 20px;
  }
}

.content-header {
  color: #666666;
  font-size: 14px;
  background: #fff;
  padding: 20px;

  span {
    color: #333333;
  }
}
.preview-layout {
  margin-top: 20px;
}
.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content-table {
    // margin: 20px;
  }
  .table-select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .table-main {
    .el-button {
      padding: 0;
    }
  }
}
</style>
